import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./AdvisorPage.css";
import Header from "../components/Header";
import ErrorPage from "./ErrorPage";

const AdvisorPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const responses = location.state?.responses || {};
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [savedProjections, setSavedProjections] = useState([]);

  const user = location.state?.user;

  const formatPrediction = (text) => {
    let formattedText = text
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')  // Bold text
      .replace(/## (.*)/g, '<h2 style="margin-bottom: 8px; margin-top: 12px;">$1</h2>')  // Convert ## to h2 with spacing
      .replace(/^\* (.*)/gm, '<li>$1</li>')  // Convert "* item" to <li>
      .replace(/(<li>.*<\/li>)/g, '<ul style="margin-top: 5px; margin-bottom: 10px;">$1</ul>')  // Wrap list items in <ul> with spacing
      .replace(/\n/g, "<br />")

    return formattedText;
  };
  
  
  
  useEffect(() => {
    const fetchPrediction = async () => {
      setLoading(true);
      try {
        const prompt = `User Financial Data:
        - Monthly Income: ${responses[0]}
        - Monthly Expenses: ${responses[1]}
        - Investments: ${responses[2]}
        - Financial Goals: ${responses[3]}
        
        Based on this data, provide a financial forecast including savings potential, investment strategies, and budgeting advice.`;

        const response = await axios.post(
          "https://generativelanguage.googleapis.com/v1/models/gemini-pro:generateContent",
          {
            contents: [{ parts: [{ text: prompt }] }],
          },
          {
            params: { key: process.env.REACT_APP_GEMINI_API_KEY },
          }
        );

        const formattedPrediction = formatPrediction(response.data?.candidates?.[0]?.content?.parts?.[0]?.text || "No response from AI.");
        
        // Save prediction for later visualization
        setMessages([{ sender: "AI", text: formattedPrediction }]);
        setSavedProjections((prevProjections) => [...prevProjections, formattedPrediction]);

      } catch (error) {
        setMessages([{ sender: "AI", text: "Error getting prediction. Please try again." }]);
        console.error("API Error:", error);
      }
      setLoading(false);
    };

    fetchPrediction();
  }, []); // Runs once when the component mounts

  const handleSendMessage = async () => {
    if (!userInput || !userInput.trim()) return;

    setMessages((prevMessages) => [...prevMessages, { sender: "User", text: userInput }]);
    setUserInput(""); // Reset user input field
    setLoading(true);

    try {
      const contextPrompt = `
      You are a financial advisor assisting with investments, savings, budgeting, and financial planning.
      Please respond based on your expertise in these areas.
      If the user asks non-financial questions, introduce yourself or provide context about your role as their financial advisor.

      User: ${userInput}
      `;

      const response = await axios.post(
        "https://generativelanguage.googleapis.com/v1/models/gemini-pro:generateContent",
        {
          contents: [{ parts: [{ text: contextPrompt }] }],
        },
        {
          params: { key: process.env.REACT_APP_GEMINI_API_KEY },
        }
      );

      const formattedResponse = formatPrediction(response.data?.candidates?.[0]?.content?.parts?.[0]?.text || "No response from AI.");
      
      setMessages((prevMessages) => [...prevMessages, { sender: "AI", text: formattedResponse }]);
      setSavedProjections((prevProjections) => [...prevProjections, formattedResponse]);

    } catch (error) {
      setMessages((prevMessages) => [...prevMessages, { sender: "AI", text: "Sorry, something went wrong. Please try again." }]);
      console.error("API Error:", error);
    }
    setLoading(false);
  };



    const handleViewProjections = () => {
        navigate("/news", { state: {user: user} });
    };

  if (!user) return <ErrorPage />;
  
  return (
    <>
      <Header showHeader={true} />
      <div className="advisor-page">
        <h1 className="title">WealthNav - Your AI Financial Advisor</h1>

        <div className="chatbox">
          <div className="messages">
            {messages.map((msg, index) => (
              <div key={index} className={msg.sender === "AI" ? "ai-message" : "user-message"}>
                <p dangerouslySetInnerHTML={{ __html: msg.text }}></p>
              </div>
            ))}

            {loading && <div className="loading-message">🤖 AI is thinking...</div>}
          </div>
          <div className="chat-input">
            <input
              type="text"
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              placeholder="Ask your financial question..."
              disabled={loading}
            />
            <button onClick={handleSendMessage} disabled={loading}>
              {loading ? "..." : "Send"}
            </button>
          </div>
        </div>

        <button className="news-button" onClick={handleViewProjections}>
          Show Financial News
        </button>
      </div>
    </>
  );
};

export default AdvisorPage;
