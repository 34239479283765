import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import QuestionCard from "../components/QuestionCard";
import './questionsPage.css';
import Header from '../components/Header';

const QuestionsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = location.state?.user;

  const [responses, setResponses] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [direction, setDirection] = useState("right");

  const questions = [
    "What is your monthly income?",
    "What are your monthly expenses?",
    "Do you have any investments?",
    "What are your financial goals?",
  ];

  const handleChange = (index, value) => {
    setResponses({ ...responses, [index]: value });
  };

  const handleSubmit = () => {
    navigate("/advisor", { state: { user: user, responses: responses } });
  };

  const handleNext = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setDirection("right");
    } else {
      handleSubmit();
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setDirection("left");
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter" && responses[currentQuestionIndex]) {
        handleNext(currentQuestionIndex);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentQuestionIndex, responses]);

  return (
    <>
      <Header showHeader={false} />
      <div className="questions-page">
        <h1 className="title">Let us get to know you a bit better!</h1>
        <div className="questions-container">
          {questions.map((question, index) => (
            <QuestionCard
              key={index}
              question={question}
              hoverFrom={
                index === currentQuestionIndex ? direction : ""
              }
              handleChange={handleChange}
              index={index}
              responses={responses}
              type={[0, 1].includes(index) ? "number" : "text"}
            />
          ))}
        </div>
        <div className="navigation-buttons">
          <button
            className={`btn previous-button ${
              currentQuestionIndex === 0 ? "" : "active"
            }`}
            onClick={handlePrevious}
            disabled={currentQuestionIndex === 0}
          >
            Previous
          </button>
          <button
            className="btn next-button active"
            onClick={() => handleNext(currentQuestionIndex)}
            disabled={!responses[currentQuestionIndex]}
          >
            {currentQuestionIndex === questions.length - 1
              ? "Submit"
              : "Next"}
          </button>
        </div>
      </div>
    </>
  );
};

export default QuestionsPage;