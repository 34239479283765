import React from 'react';
import Header from '../components/Header';
import { Link } from 'react-router';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../Firebase';

export default function LoginPage() {
    const navigate = useNavigate();

    const keyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(e);
        }
    };

    const [formData, setFormData] = React.useState({
        email: '',
        password: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, formData.email, formData.password).then((userCredential) => {
                const sanitizedUserData = {
                    uid: userCredential.user.uid,
                    email: userCredential.user.email,
                };
                navigate("/news", { state: {user: sanitizedUserData} });
            });
        } catch (error) {
            console.error(error);
        }
    }
    
    return(
        <>
            <Header showHeader={true} />
            <div className="login-page">
                <div className="login-form">
                    <h1>Login</h1>
                    <form>
                        <input 
                            type="text" 
                            placeholder="Username"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            onKeyDown={keyDown}
                        />
                        <input 
                            type="password" 
                            placeholder="Password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            onKeyDown={keyDown}
                        />
                        {/* <Link to="/"> */}
                            <button onClick={handleSubmit}>Login</button>
                        {/* </Link> */}
                        <p>Don't have an account? <Link to="/signup">Sign up</Link></p>
                    </form>
                </div>
            </div>
        </>
    );
}
