import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBvBZys00jLMSfB3XfpVCyP-vj-ILpy8b0",
    authDomain: "wealth-nav-44c22.firebaseapp.com",
    projectId: "wealth-nav-44c22",
    storageBucket: "wealth-nav-44c22.firebasestorage.app",
    messagingSenderId: "124375785205",
    appId: "1:124375785205:web:56c275ec35c38d8b3dafbc",
    measurementId: "G-CTFG444R2Z",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export default app;
