import './App.css';
import AdvisorPage from './pages/AdvisorPage';
import LandingPage from './pages/LandingPage';
import QuestionsPage from './pages/questionsPage';
import NewsPage from './pages/NewsPage';
import { Routes, Route } from 'react-router-dom';
import SignUpPage from "./pages/SignUp";
import LoginPage from './pages/LoginPage';


// WealthNav
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/questions" element={<QuestionsPage />} />
        <Route path="/advisor" element={<AdvisorPage />} />
        <Route path="/news" element={<NewsPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignUpPage />} />
      </Routes>
    </div>
  );
}

export default App;
