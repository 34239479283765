import React from 'react';
import { Link } from 'react-router';
import '../App.css';
import '../assets/css/font-awesome.min.css';
import '../assets/css/linearicons.css';
import '../assets/css/animate.css';
import '../assets/css/flaticon.css';
import '../assets/css/slick.css';
import '../assets/css/slick-theme.css';
import '../assets/css/bootstrap.min.css';
import '../assets/css/style.css';
import Header from '../components/Header';

export default function LandingPage() {
  return (
    <div className="landing-page">
        <Header showHeader={true} />

        <section id="home" className="welcome-hero">
            <div>
                <div className="welcome-hero-txt">
                    <h2>
                        Best AI Financial Advisor <br /> Chart Your Course to Financial
                        Freedom!
                    </h2>
                    <p>Your co-pilot on the journey to financial success.</p>
                </div>

                <div className="welcome-hero-serch-box" style={{ textAlign: 'center' }}>
                    <Link to="/questions" state={{ user: { uid: 'guest', email: 'guest'} } }>
                        <button className="welcome-hero-btn">
                            Start Session
                        </button>
                    </Link>
                </div>
            </div>
        </section>
    </div>
  );
}
