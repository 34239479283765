import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import MenuBar from "./MenuBar";

export default function Header({ showHeader }) {
    return (
        <section className="top-area">
            <div className="header-area">
                <nav
                    className="header-navbar"
                    data-minus-value-desktop="70"
                    data-minus-value-mobile="55"
                    data-speed="1000"
                >
                    <div className="header-title-container">
                        <div className="navbar-header">
                            <Link
                                to="/"
                                className="navbar-brand"
                                style={{ width: "auto", height: "auto" }}
                            >
                                <span>Wealth</span>
                                <span className="colored">Nav</span>
                            </Link>
                        </div>
                        <MenuBar showHeader={showHeader} />
                    </div>
                </nav>
            </div>
        </section>
    );
}