import React from "react";
import Header from "../components/Header";
import "./ErrorPage.css";

export default function ErrorPage() {
    return (
        <>
            <Header showHeader={true} />
            <div className="error-container">
                <div className="error-code">404</div>
                <div className="error-message">Page Not Found</div>
                <div className="error-description">
                    Sorry, the page you are looking for does not exist.
                </div>
            </div>
        </>
    );
}