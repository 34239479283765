import { Link } from "react-router-dom";
import React from "react";
import "./MenuBar.css";

export default function MenuBar({ showHeader }) {
    const [collapsed, setCollapsed] = React.useState(false);
    
    return showHeader ? (
          <div className="navbar-buttons" id="navbar-menu">
              {!collapsed && (
                  <>
                    <Link to="/" className="">
                        Home
                    </Link>
                    <Link to="/login" className="">
                        Login
                    </Link>
                    <Link to="/signup" className="">
                        Sign Up
                    </Link>
                  </>
              )}
          <button
              type="button"
              className="navbar-toggle"
              data-target="#navbar-menu"
              onClick={() => setCollapsed(!collapsed)}
          >
              <i className="fa fa-bars"></i>
          </button>
      </div>
  ) : null;
}