import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./NewsPage.css";
import Header from "../components/Header";
import ErrorPage from "./ErrorPage";

const NewsPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [news, setNews] = useState([]);
    const [userInput, setUserInput] = useState("");
    const [chatbotResponse, setChatbotResponse] = useState("");
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);

    const user = location.state?.user;

    useEffect(() => {
        const fetchMarketNews = async () => {
            try {
                const response = await axios.get("https://finnhub.io/api/v1/news", {
                    params: {
                        category: "general",
                        token: process.env.REACT_APP_FINNHUB_API_KEY,
                    },
                });
                setNews(response.data);
            } catch (error) {
                console.error("Error fetching market news:", error);
            }
        };
        fetchMarketNews();
    }, []);

    const formatPrediction = (text) => {
        let formattedText = text
            .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Bold text
            .replace(/## (.*)/g, '<h2 style="margin-bottom: 8px; margin-top: 12px;">$1</h2>') // Convert ## to h2 with spacing
            .replace(/^\* (.*)/gm, '<li>$1</li>') // Convert "* item" to <li>
            .replace(/(<li>.*<\/li>)/g, '<ul style="margin-top: 5px; margin-bottom: 10px;">$1</ul>'); // Wrap list items in <ul> with spacing

        return formattedText;
    };

    const handleAskChatbot = async () => {
        if (!userInput.trim()) return;
        setLoading(true);
        try {
            const response = await axios.post(
                "https://generativelanguage.googleapis.com/v1/models/gemini-pro:generateContent",
                {
                    contents: [{ parts: [{ text: `Provide market news insights on: ${userInput}` }] }],
                },
                {
                    params: { key: process.env.REACT_APP_GEMINI_API_KEY },
                }
            );
            const rawResponse = response.data?.candidates?.[0]?.content?.parts?.[0]?.text || "No response from AI.";
            const formattedResponse = formatPrediction(rawResponse);
            setChatbotResponse(formattedResponse);
        } catch (error) {
            setChatbotResponse("Error getting response. Please try again.");
            console.error("API Error:", error);
        }
        setLoading(false);
    };

    // Calculate the subset of news to display
    const newsPerPage = 4;
    const totalPages = Math.ceil(news.length / newsPerPage);
    const startIndex = currentPage * newsPerPage;
    const endIndex = startIndex + newsPerPage;
    const currentNews = news.slice(startIndex, endIndex);

    const handleNextPage = () => {
        setCurrentPage((prevPage) => (prevPage + 1) % totalPages);
    };

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => (prevPage - 1 + totalPages) % totalPages);
    };

    if (!user) return <ErrorPage />;

    return (
        <>
            <Header showHeader={true} />
            <div className="projections-page">
                <h1>Latest Market News</h1>
                <div className="news-container">
                    <div className="news-navigation">
                        <button onClick={handlePrevPage} disabled={currentPage === 0}>
                            &larr; Previous
                        </button>
                        <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                            Next &rarr;
                        </button>
                    </div>
                    <div className="news-grid">
                        {currentNews.length > 0 ? (
                            currentNews.map((article, index) => (
                                <div key={index} className="news-article">
                                    <h3>{article.headline}</h3>
                                    <p>{article.summary}</p>
                                    <a href={article.url} target="_blank" rel="noopener noreferrer" className="read-more">
                                        Read More
                                    </a>
                                </div>
                            ))
                        ) : (
                            <p>Loading market news...</p>
                        )}
                    </div>
                </div>

                <div className="chatbot-section">
                    <h2>Ask the AI Market News Chatbot</h2>
                    <div className="chatbot-container">
                        <div className="chatbot-input-container">
                            <input
                                type="text"
                                value={userInput}
                                onChange={(e) => setUserInput(e.target.value)}
                                placeholder="Ask about market news..."
                                disabled={loading}
                            />
                            <button onClick={handleAskChatbot} disabled={loading}>
                                {loading ? "Thinking..." : "Ask"}
                            </button>
                        </div>
                        {chatbotResponse && (
                            <div
                                className="chatbot-response"
                                dangerouslySetInnerHTML={{ __html: chatbotResponse }}
                            />
                        )}
                    </div>
                </div>

                <button className="back-button" onClick={() => navigate("/advisor", { state: { user: user } })}>
                    Access AI Financial Advisor
                </button>
            </div>
        </>
    );
};

export default NewsPage;