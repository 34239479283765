import React from "react";
import Cleave from 'cleave.js/react';
import "./QuestionCard.css"


export default function QuestionCard({ question, hoverFrom, handleChange, index, responses, type}) {
    return (
        <div className={`question-card ${hoverFrom ?? ""}`}>
            <label className="question-label">{question}</label>
            {type === "number" ? (
                <Cleave
                    className="question-input"
                    value={responses[index] || ""}
                    onChange={(e) => handleChange(index, e.target.rawValue)}
                    options={{
                        numeral: true,
                        numeralThousandsGroupStyle: 'thousand',
                        prefix: 'CA$ ',
                        delimiter: ',',
                    }}
                />
            ) : (
                <input
                    type="text"
                    className="question-input"
                    value={responses[index] || ""}
                    onChange={(e) => handleChange(index, e.target.value)}
                />
            )}
        </div>
    );
}
